import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { PageTemplateSimple } from '@components';
import { initialPage } from '@shared/utils/common';
import { DeploymentStatus, IAnalysisPackageDeployment } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import {
  useDeploymentFiltersQuery,
  useDeploymentListQuery,
  useLazyDeploymentStatusesQuery,
} from '@modules/library/analysisPackage/duck/analysisPackageApi';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { useStatusesListener } from '@shared/duck/sharedHooks';
import { selectAnalysisPackageModals } from '@modules/library/analysisPackage/duck/analysisPackageSelectors';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  AnalysisPackageDeploymentList,
  IAnalysisPackageDeploymentListProps,
} from '../components/AnalysisPackageDeploymentList';
import { analysisPackageActions } from '../duck/analysisPackageSlice';
import { AnalysisPackageModalsController, EAnalysisPackageType } from '../modals';

export const AnalysisPackageDeployments = () => {
  const { t } = useTranslation(['analysisPackage']);
  const dispatch = useDispatch();
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<IAnalysisPackageDeployment>(initialPage);
  const globalStudy = useSelector(selectGlobalStudy);
  const { selectedModal } = useSelector(selectAnalysisPackageModals);

  const [runStatusQuery, { isLoading: loadingStatuses }] = useLazyDeploymentStatusesQuery();
  const supportedEnvsQuery = useSupportedEnvsQuery();
  const deploymentFilters = useDeploymentFiltersQuery();
  const deploymentQuery = useDeploymentListQuery({
    page: paginationState.current - 1,
    ...preparedFilters,
    ...preparedSorter,
  });

  const isModalOpen = selectedModal ? Object.values(EAnalysisPackageType).includes(selectedModal) : false;
  const { statuses } = useStatusesListener<void, DeploymentStatus[]>(runStatusQuery, loadingStatuses, isModalOpen);

  const [deploymentData, setDeploymentData] = useState<IAnalysisPackageDeployment[]>([]);

  const pagination = getPagination(deploymentQuery.data?.totalItems);

  const onOpenLog: IAnalysisPackageDeploymentListProps['onOpenLog'] = (data) => {
    dispatch(analysisPackageActions.pushModal({ type: EAnalysisPackageType.deploymentLog, data }));
  };

  const onOpenPackage: IAnalysisPackageDeploymentListProps['onOpenPackage'] = (data) =>
    dispatch(analysisPackageActions.pushModal({ type: EAnalysisPackageType.saveAnalysisPackage, data }));

  useEffect(() => {
    const statusesData = deploymentQuery.data?.items;
    if (statusesData) {
      setDeploymentData(statusesData);
    }
  }, [deploymentQuery.data?.items]);

  return (
    <PageTemplateSimple
      title={{
        children: t('deployments.title'),
      }}
      content={{
        isLoading: deploymentQuery.isLoading && !deploymentQuery.data,
        errorText: t('deployments.loadingError'),
        error: deploymentQuery.error,
      }}
    >
      {!deploymentQuery.isLoading && (
        <AnalysisPackageDeploymentList
          supportedEnvs={supportedEnvsQuery.data || {}}
          data={deploymentData}
          environments={deploymentFilters?.data?.environments ?? []}
          studyId={globalStudy?.id!}
          onChange={onTableChange}
          onOpenLog={onOpenLog}
          onOpenPackage={onOpenPackage}
          pagination={pagination}
          loading={deploymentQuery.isFetching}
          statuses={statuses}
          t={t}
        />
      )}
      <AnalysisPackageModalsController />
    </PageTemplateSimple>
  );
};
