import { CrossStudyId } from '@config/constants';
import { getPathWithParams } from '@routes/utils';
import { StrOrNum } from '@shared/GlobalTypes';
import { include, reverse } from 'named-urls';

const studyRouteId = {
  root: 'study-root',
  view: 'study-view',
  permissions: 'study-permissions',
  instruments: 'study-instruments',
  eventDefinitions: 'study-event-definitions',
  nonCrf: 'study-non-crf',
  sources: {
    root: 'study-sources-root',
  },
  stores: {
    root: 'study-stores-root',
  },
  auditLogs: 'study-audit-logs',
  models: {
    root: 'study-models-root',
    view: 'study-models-view',
    datamapping: 'study-models-datamapping',
  },
  dataViewer: 'study-data-viewer',
  jobs: {
    root: 'study-jobs',
    view: 'study-jobs-edit',
  },
  snapshots: {
    root: 'study-snapshots',
    view: 'study-snapshots-view',
    create: 'study-snapshots-create',
  },
  gpdip: 'study-gpdip',
  dataset: 'study-dataset',

  analysisObjects: 'study-analysis-objects',
  analysisPackage: 'study-analysis-package',
  deploymentPlans: 'study-deployment-plans',
  analysisObjectsModels: {
    root: 'study-analysis-objects-models-root',
  },
  analysisObjectsSQLDatasets: {
    root: 'study-analysis-objects-sql-datasets-root',
  },
  analysisObjectsCdr: {
    root: 'study-analysis-objects-cdr-root',
  },
  analysisObjectsPsc: {
    root: 'study-analysis-objects-psc-root',
  },
};

const crossStudyRouteId = {
  root: 'cross-study-root',
  view: 'cross-study-view',
  sources: {
    root: 'cross-study-sources-root',
  },
  stores: {
    root: 'cross-study-stores-root',
  },
  auditLogs: 'cross-study-audit-logs',
  models: {
    root: 'cross-study-models-root',
    view: 'cross-study-models-view',
  },
  dataViewer: 'cross-study-data-viewer',
  jobs: {
    root: 'cross-study-jobs',
    view: 'cross-study-jobs-edit',
  },
  dataset: 'cross-study-dataset',
  stackDataset: 'stack-cross-study-dataset',
  codeLab: 'cross-study-code-lab',

  analysisObjects: 'cross-study-analysis-objects',
  analysisPackage: 'cross-study-analysis-package',
  deploymentPlans: 'cross-study-deployment-plans',
  analysisObjectsCodeLab: {
    root: 'cross-study-analysis-objects-code-lab-root',
  },
  analysisObjectsModels: {
    root: 'cross-study-analysis-objects-models-root',
  },
  analysisObjectsSQLDatasets: {
    root: 'cross-study-analysis-objects-sql-datasets-root',
  },
};

const studyRoute = include('/', {
  root: 'study',
  view: include('study/:studyId', {
    root: '',
    permissions: 'permissions',
    instruments: 'instruments',
    eventDefinitions: 'event-definitions',
    nonCrf: 'non-crf',
    sources: include('sources', {
      root: '',
    }),
    stores: include('stores', {
      root: '',
    }),
    auditLogs: 'audit',
    models: include('models', {
      root: '',
      view: include(':sourceLabel/:modelId', {
        root: '',
      }),
    }),
    dataViewer: 'data-viewer',
    jobs: include('jobs', {
      root: '',
      view: ':jobId',
    }),
    snapshots: include('snapshots', {
      root: '',
      view: ':snapshotId',
      create: 'create',
    }),
    gpdip: 'gpdip',
    dataset: 'dataset',

    analysisObjects: 'analysis-objects',
    analysisPackage: 'analysis-package',
    deploymentPlans: 'deployment',
    analysisObjectsModels: include('analysis-objects/models', {
      root: '',
    }),
    analysisObjectsSQLDatasets: include('analysis-objects/sql-lab', {
      root: '',
    }),
    analysisObjectsCdr: include('analysis-objects/cdr', {
      root: '',
    }),
    analysisObjectsPsc: include('analysis-objects/psc', {
      root: '',
    }),
  }),
});

const crossStudyRoute = include('/', {
  root: 'cross-study',
  view: include('cross-study/:studyId', {
    root: '',
    sources: include('sources', {
      root: '',
    }),
    stores: include('stores', {
      root: '',
    }),
    auditLogs: 'audit',
    models: include('models', {
      root: '',
      view: include(':sourceLabel/:modelId', {
        root: '',
      }),
    }),
    dataViewer: 'data-viewer',
    jobs: include('jobs', {
      root: '',
      view: ':jobId',
    }),
    dataset: 'dataset',
    stackDataset: 'stack-dataset',
    codeLab: 'code-lab',

    analysisObjects: 'analysis-objects',
    analysisPackage: 'analysis-package',
    deploymentPlans: 'deployment',
    analysisObjectsModels: include('analysis-objects/models', {
      root: '',
    }),
    analysisObjectsSQLDatasets: include('analysis-objects/sql-lab', {
      root: '',
    }),
    analysisObjectsCodeLab: include('analysis-objects/code-lab', {
      root: '',
    }),
  }),
});

const studyResolver = {
  root: studyRoute.root,
  view: (params: StudyResolver['view']['params']) => reverse(studyRoute.view.root, params),
  permissions: (params: StudyResolver['permissions']['params']) => reverse(studyRoute.view.permissions, params),
  instruments: (params: StudyResolver['instruments']['params']) => reverse(studyRoute.view.instruments, params),
  eventDefinitions: (params: StudyResolver['eventDefinitions']['params']) =>
    reverse(studyRoute.view.eventDefinitions, params),
  nonCrf: (params: StudyResolver['nonCrf']['params']) => reverse(studyRoute.view.nonCrf, params),
  sources: {
    root: (params: StudyResolver['sources']['root']['params']) => reverse(studyRoute.view.sources.root, params),
  },
  stores: {
    root: (params: StudyResolver['stores']['root']['params']) => reverse(studyRoute.view.stores.root, params),
  },
  auditLogs: (params: StudyResolver['auditLogs']['params']) => reverse(studyRoute.view.auditLogs, params),
  // analysisPackage: (params: StudyResolver['analysisPackage']['params']) => reverse(studyRoute.view.analysisPackage, params),
  models: {
    root: (params: StudyResolver['models']['root']['params']) => reverse(studyRoute.view.models.root, params),
    view: (params: StudyResolver['models']['view']['params']) => reverse(studyRoute.view.models.view.root, params),
  },
  dataViewer: (params: StudyResolver['dataViewer']['params']) =>
    getPathWithParams(studyRoute.view.dataViewer, { studyId: params?.studyId }, params),
  jobs: {
    root: (params: StudyResolver['jobs']['root']['params']) => reverse(studyRoute.view.jobs.root, params),
    view: (params: StudyResolver['jobs']['view']['params']) => reverse(studyRoute.view.jobs.view, params),
  },
  snapshots: {
    root: (params: StudyResolver['snapshots']['root']['params']) => reverse(studyRoute.view.snapshots.root, params),
    view: (params: StudyResolver['snapshots']['view']['params']) => reverse(studyRoute.view.snapshots.view, params),
    create: (params: StudyResolver['snapshots']['create']['params']) =>
      reverse(studyRoute.view.snapshots.create, params),
  },
  gpdip: (params: StudyResolver['gpdip']['params']) => reverse(studyRoute.view.gpdip, params),
  dataset: (params: StudyResolver['dataset']['params']) => reverse(studyRoute.view.dataset, params),

  analysisObjects: (params: StudyResolver['analysisObjects']['params']) =>
    reverse(studyRoute.view.analysisObjects, params),
  analysisPackage: (params: StudyResolver['analysisPackage']['params']) =>
    reverse(studyRoute.view.analysisPackage, params),
  deploymentPlans: (params: StudyResolver['deploymentPlans']['params']) =>
    reverse(studyRoute.view.deploymentPlans, params),
  analysisObjectsModels: {
    root: (params: StudyResolver['analysisObjectsModels']['root']['params']) =>
      reverse(studyRoute.view.analysisObjectsModels.root, params),
  },
  analysisObjectsCdr: {
    root: (params: StudyResolver['analysisObjectsCdr']['root']['params']) =>
      reverse(studyRoute.view.analysisObjectsCdr.root, params),
  },
  analysisObjectsSQLDatasets: {
    root: (params: StudyResolver['analysisObjectsSQLDatasets']['root']['params']) =>
      reverse(studyRoute.view.analysisObjectsSQLDatasets.root, params),
  },
  analysisObjectsPsc: {
    root: (params: StudyResolver['analysisObjectsPsc']['root']['params']) =>
      reverse(studyRoute.view.analysisObjectsPsc.root, params),
  },
};

const crossStudyResolver = {
  view: (params: ICrossStudyResolver['view']['params']) =>
    reverse(crossStudyRoute.view.root, { studyId: params?.studyId || CrossStudyId }),
  sources: {
    root: (params: ICrossStudyResolver['sources']['root']['params']) =>
      reverse(crossStudyRoute.view.sources.root, { studyId: params?.studyId || CrossStudyId }),
  },
  stores: {
    root: (params: ICrossStudyResolver['stores']['root']['params']) =>
      reverse(crossStudyRoute.view.stores.root, { studyId: params?.studyId || CrossStudyId }),
  },
  auditLogs: (params: ICrossStudyResolver['auditLogs']['params']) =>
    reverse(crossStudyRoute.view.auditLogs, { studyId: params?.studyId || CrossStudyId }),
  models: {
    root: (params: ICrossStudyResolver['models']['root']['params']) =>
      reverse(crossStudyRoute.view.models.root, { studyId: params?.studyId || CrossStudyId }),
    view: (params: ICrossStudyResolver['models']['view']['params']) =>
      reverse(crossStudyRoute.view.models.view.root, params),
  },
  dataViewer: (params: ICrossStudyResolver['dataViewer']['params']) =>
    getPathWithParams(crossStudyRoute.view.dataViewer, { studyId: params?.studyId || CrossStudyId }, params),
  jobs: {
    root: (params: ICrossStudyResolver['jobs']['root']['params']) =>
      reverse(crossStudyRoute.view.jobs.root, { studyId: params?.studyId || CrossStudyId }),
    view: (params: ICrossStudyResolver['jobs']['view']['params']) =>
      reverse(crossStudyRoute.view.jobs.view, { studyId: params.studyId || CrossStudyId, jobId: params.jobId }),
  },
  dataset: (params: ICrossStudyResolver['dataset']['params']) =>
    reverse(crossStudyRoute.view.dataset, { studyId: params?.studyId || CrossStudyId }),
  stackDataset: (params: ICrossStudyResolver['stackDataset']['params']) =>
    reverse(crossStudyRoute.view.stackDataset, { studyId: params?.studyId || CrossStudyId }),

  codeLab: (params: ICrossStudyResolver['codeLab']['params']) =>
    reverse(crossStudyRoute.view.codeLab, { studyId: params?.studyId || CrossStudyId }),

  analysisObjects: (params: ICrossStudyResolver['analysisObjects']['params']) =>
    reverse(crossStudyRoute.view.analysisObjects, { studyId: params?.studyId || CrossStudyId }),
  analysisPackage: (params: ICrossStudyResolver['analysisPackage']['params']) =>
    reverse(crossStudyRoute.view.analysisPackage, { studyId: params?.studyId || CrossStudyId }),
  deploymentPlans: (params: ICrossStudyResolver['deploymentPlans']['params']) =>
    reverse(crossStudyRoute.view.deploymentPlans, { studyId: params?.studyId || CrossStudyId }),
  analysisObjectsModels: {
    root: (params: ICrossStudyResolver['analysisObjectsModels']['root']['params']) =>
      reverse(crossStudyRoute.view.analysisObjectsModels.root, { studyId: params?.studyId || CrossStudyId }),
  },
  analysisObjectsSQLDatasets: {
    root: (params: ICrossStudyResolver['analysisObjectsSQLDatasets']['root']['params']) =>
      reverse(crossStudyRoute.view.analysisObjectsSQLDatasets.root, { studyId: params?.studyId || CrossStudyId }),
  },
  analysisObjectsCodeLab: {
    root: (params: ICrossStudyResolver['analysisObjectsCodeLab']['root']['params']) =>
      reverse(crossStudyRoute.view.analysisObjectsCodeLab.root, { studyId: params?.studyId || CrossStudyId }),
  },
};

export interface StudyResolver {
  view: {
    params: { studyId: StrOrNum };
  };
  permissions: {
    params: { studyId: StrOrNum };
  };
  instruments: {
    params: { studyId: StrOrNum };
  };
  eventDefinitions: {
    params: { studyId: StrOrNum };
  };
  nonCrf: {
    params: { studyId: StrOrNum };
  };
  auditLogs: {
    params: { studyId: StrOrNum };
  };
  sources: {
    root: {
      params: { studyId: StrOrNum };
    };
  };
  stores: {
    root: {
      params: { studyId: StrOrNum };
    };
  };
  models: {
    root: {
      params: { studyId: StrOrNum };
    };
    view: {
      params: { studyId: StrOrNum; modelId: StrOrNum; sourceLabel?: string };
    };
  };
  dataViewer: {
    params: { studyId: StrOrNum; tableId?: string; tableFolderName?: string; tableFolderId?: number };
  };
  jobs: {
    root: {
      params: { studyId: StrOrNum };
    };
    view: {
      params: { studyId: StrOrNum; jobId: StrOrNum };
    };
  };
  snapshots: {
    root: {
      params: { studyId: StrOrNum };
    };
    view: {
      params: { studyId: StrOrNum; snapshotId: StrOrNum };
    };
    create: {
      params: { studyId: StrOrNum };
    };
  };
  gpdip: {
    params: { studyId: StrOrNum };
  };
  dataset: {
    params: { studyId: StrOrNum };
  };

  analysisPackage: {
    params: { studyId: StrOrNum };
  };
  deploymentPlans: {
    params: { studyId: StrOrNum };
  };
  analysisObjects: {
    params: { studyId: StrOrNum };
  };
  analysisObjectsModels: {
    root: {
      params: { studyId: StrOrNum };
    };
  };
  analysisObjectsCdr: {
    root: {
      params: { studyId: StrOrNum };
    };
  };
  analysisObjectsSQLDatasets: {
    root: {
      params: { studyId: StrOrNum };
    };
  };
  analysisObjectsPsc: {
    root: {
      params: { studyId: StrOrNum };
    };
  };
}

export interface ICrossStudyResolver {
  view: {
    params: { studyId?: StrOrNum } | void;
  };
  auditLogs: {
    params: { studyId?: StrOrNum } | void;
  };
  sources: {
    root: {
      params: { studyId?: StrOrNum } | void;
    };
  };
  stores: {
    root: {
      params: { studyId?: StrOrNum } | void;
    };
  };
  models: {
    root: {
      params: { studyId?: StrOrNum } | void;
    };
    view: {
      params: { studyId?: StrOrNum; modelId: StrOrNum; sourceLabel?: string };
    };
  };
  dataViewer: {
    params: { studyId?: StrOrNum; tableId?: string; tableFolderName?: string; tableFolderId?: number } | void;
  };
  jobs: {
    root: {
      params: { studyId?: StrOrNum } | void;
    };
    view: {
      params: { studyId?: StrOrNum; jobId: StrOrNum };
    };
  };
  dataset: {
    params: { studyId?: StrOrNum } | void;
  };
  stackDataset: {
    params: { studyId?: StrOrNum } | void;
  };
  codeLab: {
    params: { studyId?: StrOrNum } | void;
  };

  analysisPackage: {
    params: { studyId?: StrOrNum } | void;
  };
  deploymentPlans: {
    params: { studyId?: StrOrNum } | void;
  };
  analysisObjects: {
    params: { studyId?: StrOrNum } | void;
  };
  analysisObjectsModels: {
    root: {
      params: { studyId?: StrOrNum } | void;
    };
  };
  analysisObjectsSQLDatasets: {
    root: {
      params: { studyId?: StrOrNum } | void;
    };
  };
  analysisObjectsCodeLab: {
    root: {
      params: { studyId?: StrOrNum } | void;
    };
  };
}

export const crossStudyRoutes = {
  root: {
    path: crossStudyRoute.root,
    id: crossStudyRouteId.root,
  },
  view: {
    path: crossStudyRoute.view.root,
    resolver: crossStudyResolver.view,
    id: crossStudyRouteId.view,
  },
  auditLogs: {
    path: crossStudyRoute.view.auditLogs,
    resolver: crossStudyResolver.auditLogs,
    id: crossStudyRouteId.auditLogs,
  },
  sources: {
    root: {
      path: crossStudyRoute.view.sources.root,
      resolver: crossStudyResolver.sources.root,
      id: crossStudyRouteId.sources.root,
    },
  },
  stores: {
    root: {
      path: crossStudyRoute.view.stores.root,
      resolver: crossStudyResolver.stores.root,
      id: crossStudyRouteId.stores.root,
    },
  },
  models: {
    root: {
      path: crossStudyRoute.view.models.root,
      resolver: crossStudyResolver.models.root,
      id: crossStudyRouteId.models.root,
    },
    view: {
      path: crossStudyRoute.view.models.view.root,
      resolver: crossStudyResolver.models.view,
      id: crossStudyRouteId.models.view,
    },
  },
  dataViewer: {
    path: crossStudyRoute.view.dataViewer,
    resolver: crossStudyResolver.dataViewer,
    id: crossStudyRouteId.dataViewer,
  },
  jobs: {
    view: {
      path: crossStudyRoute.view.jobs.view,
      resolver: crossStudyResolver.jobs.view,
      id: crossStudyRouteId.jobs.view,
    },
    root: {
      path: crossStudyRoute.view.jobs.root,
      resolver: crossStudyResolver.jobs.root,
      id: crossStudyRouteId.jobs.root,
    },
  },
  dataset: {
    path: crossStudyRoute.view.dataset,
    resolver: crossStudyResolver.dataset,
    id: crossStudyRouteId.dataset,
  },
  stackDataset: {
    path: crossStudyRoute.view.stackDataset,
    resolver: crossStudyResolver.stackDataset,
    id: crossStudyRouteId.stackDataset,
  },
  codeLab: {
    path: crossStudyRoute.view.codeLab,
    resolver: crossStudyResolver.codeLab,
    id: crossStudyRouteId.codeLab,
  },

  analysisObjects: {
    path: crossStudyRoute.view.analysisObjects,
    resolver: crossStudyResolver.analysisObjects,
    id: crossStudyRouteId.analysisObjects,
  },
  analysisPackage: {
    path: crossStudyRoute.view.analysisPackage,
    resolver: crossStudyResolver.analysisPackage,
    id: crossStudyRouteId.analysisPackage,
  },
  deploymentPlans: {
    path: crossStudyRoute.view.deploymentPlans,
    resolver: crossStudyResolver.deploymentPlans,
    id: crossStudyRouteId.deploymentPlans,
  },
  analysisObjectsModels: {
    root: {
      path: crossStudyRoute.view.analysisObjectsModels.root,
      resolver: crossStudyResolver.analysisObjectsModels.root,
      id: crossStudyRouteId.analysisObjectsModels.root,
    },
  },
  analysisObjectsSQLDatasets: {
    root: {
      path: crossStudyRoute.view.analysisObjectsSQLDatasets.root,
      resolver: crossStudyResolver.analysisObjectsSQLDatasets.root,
      id: crossStudyRouteId.analysisObjectsSQLDatasets.root,
    },
  },
  analysisObjectsCodeLab: {
    root: {
      path: crossStudyRoute.view.analysisObjectsCodeLab.root,
      resolver: crossStudyResolver.analysisObjectsCodeLab.root,
      id: crossStudyRouteId.analysisObjectsCodeLab.root,
    },
  },
};

export default {
  root: {
    path: studyRoute.root,
    resolver: studyResolver.root,
    id: studyRouteId.root,
  },
  view: {
    path: studyRoute.view.root,
    resolver: studyResolver.view,
    id: studyRouteId.view,
  },
  permissions: {
    path: studyRoute.view.permissions,
    resolver: studyResolver.permissions,
    id: studyRouteId.permissions,
  },
  instruments: {
    path: studyRoute.view.instruments,
    resolver: studyResolver.instruments,
    id: studyRouteId.instruments,
  },
  eventDefinitions: {
    path: studyRoute.view.eventDefinitions,
    resolver: studyResolver.eventDefinitions,
    id: studyRouteId.eventDefinitions,
  },
  nonCrf: {
    path: studyRoute.view.nonCrf,
    resolver: studyResolver.nonCrf,
    id: studyRouteId.nonCrf,
  },
  auditLogs: {
    path: studyRoute.view.auditLogs,
    resolver: studyResolver.auditLogs,
    id: studyRouteId.auditLogs,
  },
  sources: {
    root: {
      path: studyRoute.view.sources.root,
      resolver: studyResolver.sources.root,
      id: studyRouteId.sources.root,
    },
  },
  stores: {
    root: {
      path: studyRoute.view.stores.root,
      resolver: studyResolver.stores.root,
      id: studyRouteId.stores.root,
    },
  },
  models: {
    root: {
      path: studyRoute.view.models.root,
      resolver: studyResolver.models.root,
      id: studyRouteId.models.root,
    },
    view: {
      path: studyRoute.view.models.view.root,
      resolver: studyResolver.models.view,
      id: studyRouteId.models.view,
    },
  },
  dataViewer: {
    path: studyRoute.view.dataViewer,
    resolver: studyResolver.dataViewer,
    id: studyRouteId.dataViewer,
  },
  jobs: {
    view: {
      path: studyRoute.view.jobs.view,
      resolver: studyResolver.jobs.view,
      id: studyRouteId.jobs.view,
    },
    root: {
      path: studyRoute.view.jobs.root,
      resolver: studyResolver.jobs.root,
      id: studyRouteId.jobs.root,
    },
  },
  snapshots: {
    root: {
      path: studyRoute.view.snapshots.root,
      resolver: studyResolver.snapshots.root,
      id: studyRouteId.snapshots.root,
    },
    view: {
      path: studyRoute.view.snapshots.view,
      resolver: studyResolver.snapshots.view,
      id: studyRouteId.snapshots.view,
    },
    create: {
      path: studyRoute.view.snapshots.create,
      resolver: studyResolver.snapshots.create,
      id: studyRouteId.snapshots.create,
    },
  },
  gpdip: {
    path: studyRoute.view.gpdip,
    resolver: studyResolver.gpdip,
    id: studyRouteId.gpdip,
  },
  dataset: {
    path: studyRoute.view.dataset,
    resolver: studyResolver.dataset,
    id: studyRouteId.dataset,
  },

  analysisObjects: {
    path: studyRoute.view.analysisObjects,
    resolver: studyResolver.analysisObjects,
    id: studyRouteId.analysisObjects,
  },
  analysisPackage: {
    path: studyRoute.view.analysisPackage,
    resolver: studyResolver.analysisPackage,
    id: studyRouteId.analysisPackage,
  },
  deploymentPlans: {
    path: studyRoute.view.deploymentPlans,
    resolver: studyResolver.deploymentPlans,
    id: studyRouteId.deploymentPlans,
  },
  analysisObjectsModels: {
    root: {
      path: studyRoute.view.analysisObjectsModels.root,
      resolver: studyResolver.analysisObjectsModels.root,
      id: studyRouteId.analysisObjectsModels.root,
    },
  },
  analysisObjectsCdr: {
    root: {
      path: studyRoute.view.analysisObjectsCdr.root,
      resolver: studyResolver.analysisObjectsCdr.root,
      id: studyRouteId.analysisObjectsCdr.root,
    },
  },
  analysisObjectsSQLDatasets: {
    root: {
      path: studyRoute.view.analysisObjectsSQLDatasets.root,
      resolver: studyResolver.analysisObjectsSQLDatasets.root,
      id: studyRouteId.analysisObjectsSQLDatasets.root,
    },
  },
  analysisObjectsPsc: {
    root: {
      path: studyRoute.view.analysisObjectsPsc.root,
      resolver: studyResolver.analysisObjectsPsc.root,
      id: studyRouteId.analysisObjectsPsc.root,
    },
  },
};
