import { useStackDatasetListPaginatedQuery } from '@modules/dataset/duck/datasetApi';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { PageTemplateSimple } from '@components';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { DatasetList } from '@modules/dataset/components';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { initialPage } from '@shared/utils/common';
import { IDataset } from '@modules/dataset/DatasetTypes';
import { SYSTEM_STORE_NAMES } from '@modules/stores/duck/storeConstants';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const StackDatasetsRootPage = () => {
  const { t } = useTranslation('dataset');
  const { userPermissionsReady } = useStudyPermissions();
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<IDataset>(initialPage);

  const globalStudy = useSelector(selectGlobalStudy);
  const datasetQuery = useStackDatasetListPaginatedQuery({
    study_id: globalStudy?.id!,
    page: paginationState.current - 1,
    ...preparedFilters,
    ...preparedSorter,
  });

  const pagination = getPagination(datasetQuery.data?.totalItems);

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        children: t('stackPageRootName'),
      }}
      content={{
        isLoading: datasetQuery.isLoading && !datasetQuery.data && userPermissionsReady,
        errorText: t('stackLoadingError'),
        error: datasetQuery.error,
      }}
    >
      {datasetQuery.data && (
        <DatasetList
          isStackType
          data={datasetQuery.data.items}
          onChange={onTableChange}
          pagination={pagination}
          loading={datasetQuery.isFetching}
          studyId={globalStudy?.id!}
          viewDatasetFolder={SYSTEM_STORE_NAMES.GLOBAL_STACK_DATA}
          t={t}
        />
      )}
    </PageTemplateSimple>
  );
};
